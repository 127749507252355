<template>
  <div
    style="height: 72px; justify-content: center"
    class="px-4 d-flex align-center"
  >
    <v-progress-circular size="25" width="2" indeterminate color="primary" />
  </div>
</template>

<script>
export default {
  name: 'ListItemLoading'
};
</script>
